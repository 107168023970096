import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout/MainLayout";
import { MediaGallery } from "../components/common/MediaGallery";
import get from "lodash/get";
import { getAllMedia } from "../components/common/MediaGallery/util/tagging";
import { siteTitle, subdomainUrl, twitter } from "../vars/helpers";
import { SEO } from "wmk-lib";

const MediaTag = ({ pageContext }) => {
  const { videos, photos, vimeo } = useStaticQuery(query);
  const media = getAllMedia(videos, photos, vimeo);
  console.log(pageContext);
  /* TO DO 
      - Aggregate photos and videos by category into single array of objects
      - The key of each object will correspond to the item's tag
      - For each tag with photos & videos, generate a thumbnail (perhaps using the item at index [0]), to drill into each category
      - May have to write something in gatsby-node to turn each "tag" into a route so can use breadcrumbs
      - Each category page will display photo or video thumbs that open in lightbox
  */

  return (
    <Layout>
      <SEO
        baseUrl={subdomainUrl}
        title={get(pageContext, `tag.title`)}
        siteTitle={siteTitle + " Photos & Videos"}
        slug={`photos-videos/${get(pageContext, "tag.slug")}`}
        twitterHandle={twitter}
        twitterImage={null}
        ogImage={null}
      />
      <MediaGallery
        // photos={photosByCategory}
        // videos={videosByCategory}
        init={get(pageContext, "tag")}
        media={media}
        // width={vidImgTileWidth}
        // height={vidImgTileHeight}
      />
    </Layout>
  );
};

export default MediaTag;

const query = graphql`
  {
    photos: allFlickrPhoto {
      edges {
        node {
          ...NodeFlickrFields
        }
      }
    }
    videos: allYoutubeVideo {
      edges {
        node {
          ...NodeYoutubeFields
        }
      }
    }
    vimeo: allVimeoVideo {
      edges {
        node {
          ...NodeVimeoFields
        }
      }
    }
  }
`;
